@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.replace {
  @include fixedSize(116px, 36px);

  span[id='button-span-text'] {
    @include typography-s;
    @include weight-semi-bold;
  }

  @include min-428-break {
    @include fixedSize(160px, 44px);

    span[id='button-span-text'] {
      @include typography-base;
    }
  }

  @include min-744-break {
    @include fixedSize(160px, 44px);
  }

  @include min-1024-break {
    @include fixedSize(120px, 34px);
  }

  @include min-1440-break {
    @include fixedSize(160px, 42px);
  }
}

.icon {
  @include singleFixedSize(18px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }

  @include min-1024-break {
    @include singleFixedSize(16px);
  }

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
