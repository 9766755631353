@use '@fixed-size' as *;
@use 'sass:map';
@use '@queries' as *;

$height: (
  '32': 32px,
  '34': 34px,
  '36': 36px,
  '38': 38px,
  '40': 40px,
  '42': 42px,
  '44': 44px,
  '46': 46px,
  '48': 48px,
  '50': 50px,
  '52': 52px,
  '54': 54px,
);

$font-sizes: (
  '32': 12px,
  '34': 12px,
  '36': 12px,
  '38': 12px,
  '40': 14px,
  '42': 14px,
  '44': 14px,
  '46': 14px,
  '48': 14px,
  '50': 14px,
  '52': 14px,
  '54': 14px,
);

$iconSize: (
  '32': 16px,
  '34': 16px,
  '36': 18px,
  '38': 18px,
  '40': 18px,
  '42': 18px,
  '44': 20px,
  '46': 20px,
  '48': 22px,
  '50': 22px,
  '52': 22px,
  '54': 22px,
);

$radius: (
  '32': 6px,
  '34': 6px,
  '36': 6px,
  '38': 8px,
  '40': 8px,
  '42': 8px,
  '44': 10px,
  '46': 10px,
  '48': 10px,
  '50': 10px,
  '52': 12px,
  '54': 12px,
);

@mixin buttonSizes(
  $size320,
  $size428,
  $size744,
  $size1024,
  $size1440,
  $size1920
) {
  height: map.get($height, $size320);
  border-radius: map.get($radius, $size320);
  svg {
    @include singleFixedSize(map.get($iconSize, $size320));
  }
  span[id='button-span-text'] {
    font-size: map.get($font-sizes, $size320);
  }

  @include min-428-break {
    height: map.get($height, $size428);
    border-radius: map.get($radius, $size428);
    svg {
      @include singleFixedSize(map.get($iconSize, $size428));
    }
    span[id='button-span-text'] {
      font-size: map.get($font-sizes, $size428);
    }
  }

  @include min-744-break {
    height: map.get($height, $size744);
    border-radius: map.get($radius, $size744);
    svg {
      @include singleFixedSize(map.get($iconSize, $size744));
    }
    span[id='button-span-text'] {
      font-size: map.get($font-sizes, $size744);
    }
  }

  @include min-1024-break {
    height: map.get($height, $size1024);
    border-radius: map.get($radius, $size1024);
    svg {
      @include singleFixedSize(map.get($iconSize, $size1024));
    }
    span[id='button-span-text'] {
      font-size: map.get($font-sizes, $size1024);
    }
  }

  @include min-1440-break {
    height: map.get($height, $size1440);
    border-radius: map.get($radius, $size1440);
    svg {
      @include singleFixedSize(map.get($iconSize, $size1440));
    }
    span[id='button-span-text'] {
      font-size: map.get($font-sizes, $size1440);
    }
  }

  @include min-1920-break {
    height: map.get($height, $size1920);
    border-radius: map.get($radius, $size1920);
    svg {
      @include singleFixedSize(map.get($iconSize, $size1920));
    }
    span[id='button-span-text'] {
      font-size: map.get($font-sizes, $size1920);
    }
  }
}
